import React, { lazy, useEffect, useState } from "react";
import { Link } from "gatsby";
import { Tabla } from "./Tabla";
import { Seo } from "../Layout/Seo";
import GatsbyImages from "../GatsbyImages";

import "./postPage.css";

const MoneyRecharge = lazy(() =>
  import("../MoneyRecharge").then((module) => ({
    default: module.MoneyRecharge,
  })),
);

const MoneyConvertor = lazy(() =>
  import("../MoneyConvertor").then((module) => ({
    default: module.MoneyConvertor,
  })),
);

const PostPage = ({ pageContext, location, lang }, props) => {
  const { blog, resultfooterpost, flagsPricingSendities, country_code } =
    pageContext;

  // const url = location.href;
  const [updatedText, setUpdatedText] = useState([]);
  const [listText, setListText] = useState("");
  useEffect(() => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
    document.getElementById("header").classList.add("stick_header");
  }, []);

  useEffect(() => {
    if (!blog.text) return;
    const addNoFollowsToExternal = ({ html }) => {
      const internalLinkRegex = /https?:\/\/(?:www\.)?sendity\.com/i;
      const anchorRegex =
        /(<a\s+(?!.*\brel=)[^>]*)(href="https?:\/\/[^"]+")([^>]*)(?:\/?>)/gi;
      return html.replace(anchorRegex, function (match, p1, p2, p3) {
        if (!internalLinkRegex.test(p2)) {
          return p1 + p2 + p3 + ' rel="nofollow">';
        } else {
          return match;
        }
      });
    };

    const createList = async ({ doc }) => {
      let i = 0;
      let array = [];

      const list = doc.querySelectorAll("h2, h3, h4");
      list.forEach((e, index) => {
        if (e.innerText.length > 1) {
          e.setAttribute("id", `${e.localName}_${index}`);
        } else {
          array[i] = index;
          i++;
        }
      });
      const listcopu = Object.keys(list).map((key) => [list[key]]);
      for (let i = 0; i < array.length; i++) {
        listcopu.splice(array[i] - i, 1);
      }
      doc = new XMLSerializer().serializeToString(doc);
      setListText(listcopu);
      // setUpdatedText(doc);
    };

    const checkForSendityUrls = async ({ doc }) => {
      const iframes = [...doc.getElementsByTagName("iframe")].filter(
        (iframe) =>
          iframe.src.includes("calculadora-recargas") ||
          iframe.src.includes("/calculadora/"),
      );

      let updatedDocArray = [];
      let fullDocHtml = doc.documentElement.outerHTML;

      let lastIndex = 0; // To track the position in the document

      iframes?.forEach((iframe, index) => {
        const params = new URL(iframe.src).searchParams;

        const country = params.get("country");
        const lang = params.get("lang") || "es"; // Fallback if lang is not present
        const isRecargas = iframe.src.includes("calculadora-recargas");

        const calculatorProps = {
          country_name: country,
          lang,
          link: true,
        };

        if (!isRecargas) {
          calculatorProps.locationName = "link";
          calculatorProps.flagsPricingSendities = flagsPricingSendities;
          calculatorProps.country_code = country_code;
          calculatorProps.location = iframe.src;
        }

        // Create a container for replacing the iframe
        const container = doc.createElement("div");
        container.id = `react-injection-container-${index}`;
        container.className = "money-convertor_calculator_recharges-page";

        // Get the HTML up to the iframe (text before iframe)
        const indexOfIframe = fullDocHtml.indexOf(iframe.outerHTML, lastIndex);
        const textBefore = fullDocHtml.substring(lastIndex, indexOfIframe);

        // Push the text before the iframe into the array
        if (textBefore) {
          updatedDocArray.push({ text: textBefore }); // Add the text chunk
        }

        // Push the React component replacing the iframe into the array
        updatedDocArray.push({
          component: isRecargas ? (
            <MoneyRecharge {...calculatorProps} key={index} />
          ) : (
            <MoneyConvertor {...calculatorProps} key={index} />
          ), // React component replacing iframe
        });

        // Update the last index position after replacing the iframe
        lastIndex = indexOfIframe + iframe.outerHTML.length;

        // Replace the iframe with the container in the actual DOM (if needed for immediate rendering)
        iframe.parentNode.replaceWith(container);
      });

      // Add the remaining text after the last iframe/component
      const remainingText = fullDocHtml.substring(lastIndex);
      updatedDocArray.push({ text: remainingText });
      // Set the updated array to state
      setUpdatedText(updatedDocArray);
    };

    const getDoc = async ({ html }) => {
      let newText = html;
      let doc = new DOMParser().parseFromString(newText, "text/html");

      await createList({ doc });

      await checkForSendityUrls({ doc });
    };

    const contentWithNoFollowToExternal = addNoFollowsToExternal({
      html: blog.text,
    });

    getDoc({ html: contentWithNoFollowToExternal });
  }, [blog.text]);

  const footerpost = resultfooterpost.filter(
    ({ locale }) => locale === lang,
  )[0];

  const formatDate = (dateString) => {
    const [year, day, month] = dateString.split("/"); // Split into year, day, and month
    const date = new Date(`${year}-${month}-${day}`); // Rearrange to a valid date format (yyyy-mm-dd)

    const options = { day: "2-digit", month: "long", year: "numeric" };
    const langFormat = lang === "es" ? "es-ES" : "en-GB";
    return new Intl.DateTimeFormat(langFormat, options)
      .format(date)
      .replace(" de 2", ", 2");
  };

  const goBackLink = lang === "en" ? "/en/blog/" : "/blog/";

  return (
    <Seo
      title={pageContext.blog.seoTitle}
      description={pageContext.blog.seoDescription}
    >
      <section className="section-block p-4 mt-4 article-body" id="post">
        <div className="container">
          <GatsbyImages
            src={blog.image.localFile.childImageSharp.gatsbyImageData}
            className="content-image-unic"
            ext={blog.image.localFile.ext}
            alt={blog.image.alternativeText}
          />
          <div className="post-header">
            <Link to={goBackLink} className="volver">
              <span></span>Volver
            </Link>
            <h1>{blog.title}</h1>
            <ul className="headpost">
              <li>
                {blog.category.Title} · {formatDate(blog.date)}
              </li>
              <li>
                {blog.reading_time}‘<span></span>
              </li>
            </ul>
          </div>
          <div className="postdetail">
            <div className="indexing">
              <div className="indexlist">
                <div className="indextext">Índice</div>
                <Tabla content={listText} />
              </div>
            </div>
            <div className="post-item">
              <div className="col-12 ">
                {updatedText.length
                  ? updatedText.map(({ text, component }, index) => {
                      if (component) {
                        const isCalculadora = component.props?.locationName;
                        return (
                          <div
                            className="iframe-crusted"
                            style={{
                              "--iframe-crusted_bg": isCalculadora
                                ? "#113a4c"
                                : "#59518e",
                              "--iframe-crusted_display": isCalculadora
                                ? "flex"
                                : "block",
                              "--iframe-crusted_padding": isCalculadora
                                ? "8px 24px"
                                : "4px 4px",
                              minHeight: "352px",
                            }}
                            key={index}
                          >
                            {component}
                          </div>
                        );
                      }
                      if (text) {
                        return (
                          <div
                            className="color: #939393;"
                            key={index}
                            dangerouslySetInnerHTML={{
                              __html: text,
                            }}
                          />
                        );
                      }
                    })
                  : null}
                <div className="advertisment">{footerpost.Text}</div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="cta">
        <div className="container">
          <div className="avail-in d-flex flex-wrap">
            <div className="service-in col-lg-7 col-md-12">
              <h4>
                {lang === "en"
                  ? "Send your money at the real market price with Sendity"
                  : "Envía tu dinero al precio real del mercado con Sendity"}
              </h4>
            </div>
            <div className="whatsapp col-lg-5 col-md-12">
              <Link to="/enviar-dinero">
                {lang === "en" ? "I'm in!" : "¡Me apunto!"}
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="cards-block">
        <div className="container">
          <div className="cards-in">
            <h3>{lang === "en" ? "Read more" : "Seguir leyendo"}</h3>
            <div className="cards">
              <ul className="clearfix autoplay d-flex flex-wrap">
                {blog.blogsBelongTo.map(
                  ({ text, path, thumbnail, date, title }, index) => {
                    const match = /<p[^>]*>(.*?)<\/p>/i.exec(text);
                    const firstPContent = match ? match[0] : "";

                    const formattedDate = formatDate(date);
                    return (
                      <li className="" key={index}>
                        <Link
                          to={
                            lang === "en" ? `/en/blog/${path}` : `/blog/${path}`
                          }
                        >
                          <div className="cardWrapper">
                            <div className="card1">
                              <GatsbyImages
                                src={
                                  thumbnail.localFile.childImageSharp
                                    .gatsbyImageData
                                }
                                ext={thumbnail.localFile.ext}
                                className="content-image"
                                alt={thumbnail.alternativeText}
                              />
                              <div className="category">
                                <span>{blog.category.Title}</span>
                              </div>
                            </div>
                            <div className="card-content">
                              <span>{formattedDate}</span>
                              <h5>{title}</h5>
                              <div
                                className="card-text"
                                dangerouslySetInnerHTML={{
                                  __html: firstPContent,
                                }}
                              ></div>
                            </div>
                          </div>
                        </Link>
                      </li>
                    );
                  },
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Seo>
  );
};

export default PostPage;

// urls calculadora
//      <iframe
//      name="sendity"
//      src="https://www.sendity.com/calculadora/?country=marruecos&&lang=es"
//    ></iframe>
//       <iframe
//      name="sendity"
//      src="https://www.sendity.com/calculadora/?country=morocco&&lang=en"
//    ></iframe>
//       <iframe
//      name="sendity"
//      src="https://www.sendity.com/calculadora-recargas/?country=marruecos&&lang=es"
//    ></iframe>

//    <iframe
//      name="sendity"
//      src="https://www.sendity.com/calculadora-recargas/?country=republica-dominicana&&lang=es"
//    ></iframe>
// <iframe
//      name="sendity"
//      src="https://www.sendity.com/calculadora-recargas/?country=dominican-republic&&lang=en"
//    ></iframe>
