import React, { Component } from "react";

class Tabla extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    if (this.props.content === "") return null;
    const info = this.props.content;
    const component = [];
    let num1 = 0;
    let num2 = 0;
    let num3 = 0;
    let num4 = 0;
    info.forEach((e, index) => {
      let aux = [];
      aux.id = e[0].id;
      aux.text = e[0].innerText;
      if (e[0].localName === "h2") {
        aux.level = 1;
        num1++;
        aux.num = num1;
        num2 = 0;
      } else if (e[0].localName === "h3") {
        aux.level = 2;
        num2++;
        aux.num = `${num1}.${num2}`;
        num3 = 0;
      } else if (e[0].localName === "h4") {
        aux.level = 3;
        num3++;
        aux.num = `${num1}.${num2}.${num3}`;
        num4 = 0;
      } else if (e[0].localName === "h5") {
        aux.level = 4;
        num4++;
        aux.num = `${num1}.${num2}.${num3}.${num4}`;
      }
      component[index] = aux;
    });

    return (
      <React.Fragment>
        {component.map((value, index) => (
          <div
            key={index}
            className={
              value.level === 1
                ? "tabla-text1"
                : value.level === 2
                ? "tabla-text2"
                : value.level === 3
                ? "tabla-text3"
                : value.level === 4
                ? "tabla-text4"
                : ""
            }
          >
            <a href={`#${value.id}`}> {`${value.num} ${value.text}`}</a>
          </div>
        ))}
      </React.Fragment>
    );
  }
}
export { Tabla };
